import React from "react"
import Helmet from "react-helmet"
import NavigationBar from "./Navigation"
import config from "../../data/SiteConfig"
import "bootstrap/dist/css/bootstrap.min.css"

export default ({ children }) => (
  <div>
    <Helmet>
      <html lang="en" />
      <title>{config.siteTitle}</title>
      <meta name="description" content={config.siteDescription} />
      <meta property="og:title" content={config.siteTitle} />
      <meta property="og:url" content="/" />
    </Helmet>
    <NavigationBar />
    <div>{children}</div>
  </div>
)

import React from "react"
import { Nav } from "react-bootstrap"
import { FaAngleDown } from "react-icons/fa"
import { Link } from "gatsby"
const ListLink = props => <Nav.Link href={props.to}>{props.children}</Nav.Link>

const NavigationBar = class extends React.Component {
  render() {
    return (
      <header className="header-global">
        <nav
          id="navbar-main"
          className="navbar navbar-main navbar-expand-lg @@classes py-lg-2 px-lg-6"
        >
          <div className="container">
            <a className="navbar-brand" href="/">
              <img
                className="navbar-brand-dark"
                src="/tb_test-01.svg"
                alt="Logo light"
              ></img>
            </a>
            <div className="navbar-collapse collapse" id="navbar_global">
              <div className="navbar-collapse-header">
                <div className="row">
                  <div className="col-6 collapse-brand">
                    <a href="/">
                      <img src="/tb_test-01.svg" alt="Menu logo" />
                    </a>
                  </div>
                  {/* <div className="col-6 collapse-close">
                    <a
                      href="#navbar_global"
                      className="fas fa-times"
                      data-toggle="collapse"
                      data-target="#navbar_global"
                      aria-controls="navbar_global"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    ></a>
                  </div> */}
                </div>
              </div>
              <ul className="navbar-nav navbar-nav-hover ml-3 ml-auto">
                <li className="nav-item">
                  <ListLink to="/blog/" className="nav-link">
                    Blog
                  </ListLink>
                </li>
                <li className="nav-item dropdown">
                  <a href="" className="nav-link" data-toggle="dropdown">
                    <span className="nav-link-inner-text">Brokers</span>
                    <i className="fas fa-angle-down nav-link-arrow">
                      <FaAngleDown></FaAngleDown>
                    </i>
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <Link to="/new-south-wales/" className="dropdown-item">
                        New South Wales
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/victoria/" className="dropdown-item">
                        Victoria
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/queensland/" className="dropdown-item">
                        Queensland
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/western-australia/" className="dropdown-item">
                        Western Australia
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/south-australia/" className="dropdown-item">
                        South Australia
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/tasmania/" className="dropdown-item">
                        Tasmania
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/australian-capital-territory/"
                        className="dropdown-item"
                      >
                        Australian Capital Terriroty
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <ListLink to="/about/" className="nav-link">
                    About
                  </ListLink>
                </li>
                <li className="nav-item">
                  <ListLink to="/contact/" className="nav-link">
                    Contact
                  </ListLink>
                </li>
              </ul>
            </div>
            <div className="d-none d-lg-block">
              <Link
                to="/contact/"
                className="btn btn-sm btn-primary animate-up-1 ml-3"
              >
                I'm a broker
              </Link>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default NavigationBar

import React from "react"
import "../styles/spaces.css"
import { Link } from "gatsby"
import SubscriptionForm from "./SubscriptionForm"
const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer section bg-dark text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-lg-4">
              <a className="footer-brand" href="/">
                <img src="/tb_light.svg" alt="Brand logo footer" />
              </a>
              <p className="mt-4 text-gray">
                Let us help you find your nearby <strong>topbroker</strong> and
                feel confident that you're in safe hands.
              </p>
              <ul className="social-buttons mb-5 mb-lg-0">
                <li>
                  <a
                    href="https://twitter.com/themesberg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-lg btn-link btn-twitter text-white"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Follow us on Twitter"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/themesberg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-lg btn-link btn-facebook text-white"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Like us on Facebook"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/themesberg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-lg btn-link btn-github text-white"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Star us on Github"
                  >
                    <i className="fab fa-github"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://dribbble.com/themesberg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-lg btn-link btn-dribbble text-white"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Like us on Dribbble"
                  >
                    <i className="fab fa-dribbble"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-4 mt-4 mt-md-0">
              <h6 className="font-weight-normal">Pages</h6>
              <ul className="links-vertical">
                <li>
                  <Link to="/blog/" className="text-gray">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to="/about/" className="text-gray">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/contact/" className="text-gray">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-md-3 col-lg-4 col-xl-4 mt-3 mt-lg-0 mt-4 mt-md-0">
              <h6 className="font-weight-normal">Subscribe</h6>
              <p className="text-gray">
                Join our mailing list. We write rarely, but only the best
                content.
              </p>
              <SubscriptionForm></SubscriptionForm>
              <small className="text-gray mt-3 form-text">
                We’ll never share your details. See our{" "}
                <Link to="/privacy/" className="text-light">
                  Privacy Policy
                </Link>
              </small>
            </div>
          </div>
          <hr />
          <div className="copyright text-center mt-5">
            &#xA9; <span id="current-year"></span>{" "}
            <a href="https://topbrokers.com.au">TopBrokers.com.au</a>. All
            rights reserved.
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer

import React from "react"

const SubscriptionForm = () => (
  <form name="subscribe" method="POST" data-netlify="true">
    <div className="form-row">
      <div className="col-12">
        <input
          type="email"
          className="form-control mb-2"
          placeholder="Email Address"
          name="email"
          required
        />
      </div>
      <div className="col-12">
        <button
          type="submit"
          className="btn btn-secondary shadow-soft btn-block"
          data-loading-text="Sending"
        >
          <span>Subscribe</span>
        </button>
      </div>
    </div>
  </form>
)

export default SubscriptionForm
